
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as articlevUbztdBt_45kY9p5_45KbWF1YIXt8Oq_45NB267FWkN8Rjz8QMeta } from "/opt/buildhome/repo/pages/article.vue?macro=true";
import { default as termsM_RN9_459iuNVtH23UOLrjvoINYU4b2nnq0grhd_RmiYYMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as termNFMIQ_PMkaujhYlHeuBiHQJYtEm3saDlugGWqOL04tkMeta } from "/opt/buildhome/repo/pages/term.vue?macro=true";
import { default as clienttestGfCa8a2_45Bg0I3_45noZVmfNTJjtrMiXn_45JuffApoGMRiYMeta } from "/opt/buildhome/repo/pages/clienttest.vue?macro=true";
import { default as prices6d4LxWc2omt5Cpk4box86hrKcGoePl45sVlas5KHd_45gMeta } from "/opt/buildhome/repo/pages/prices.vue?macro=true";
import { default as cookieinfoorrfT1X_a1MISOUEipXQ96kcApnIeovsa_19Hgx_ucwMeta } from "/opt/buildhome/repo/pages/cookieinfo.vue?macro=true";
import { default as loginKMdcZdjxVWBby7qzc8iax1m1i6js8gN3t8PvZM_RwR8Meta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as ssoLoginojbpxvnfsPjvzJ8xuCGIo3fOhiyomBPaCsor582zEm0Meta } from "/opt/buildhome/repo/pages/ssoLogin.vue?macro=true";
import { default as legacyKIas2PJMkGNlB34OLcpStfz_bcqh2cczvKw5_45PSBlJAMeta } from "/opt/buildhome/repo/pages/legacy.vue?macro=true";
import { default as indexsSHEynImpmVJQBsgEWtpFbTIimvAlNbjHy0W37sieOIMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as profilelYJWJLXKCDoxakjw7sVPapCxOwP6E9HYlUzzKi6Xhy0Meta } from "/opt/buildhome/repo/pages/account/profile.vue?macro=true";
import { default as loginsecurityEL0Mk4ppj7JsAU5PpThBz751WoLjAITw2_HiOAflYy0Meta } from "/opt/buildhome/repo/pages/account/loginsecurity.vue?macro=true";
import { default as savedArticlesRn0lylgoTP3f4sGTTQtkZSSEzSL2eboC0kYDGMKGZ1kMeta } from "/opt/buildhome/repo/pages/account/savedArticles.vue?macro=true";
import { default as decisionchainsVbDd_MNA7Vy2qFrYtii_45WpliXu8a2Rs66trvmYlPpTsMeta } from "/opt/buildhome/repo/pages/account/decisionchains.vue?macro=true";
import { default as newsletterJvVDqu527IILkYvJvCLXifJdzBQyrGvtduNVSh_45qPMQMeta } from "/opt/buildhome/repo/pages/account/newsletter.vue?macro=true";
import { default as subscriptionV6NtWMj1DyVaX0zlzWTuNYTRGSLzBxHKUOnEqs_FWxcMeta } from "/opt/buildhome/repo/pages/account/subscription.vue?macro=true";
import { default as userCreateAd20DSRaJXQW_45p7mNOP0ZPLO4pDWKEOtu_45mUWv_45fNe0Meta } from "/opt/buildhome/repo/pages/userCreate.vue?macro=true";
import { default as forgotPassword2d9h4ddAmdrx5DWOy69v4odaTLhdb_45_45G04Y0JjXEiOgMeta } from "/opt/buildhome/repo/pages/user/forgotPassword.vue?macro=true";
import { default as userSetPasswordu1APOcfO0OoTq8HhFeD0I7XUS_Y7sn8eBw3MhGb_RZoMeta } from "/opt/buildhome/repo/pages/userSetPassword.vue?macro=true";
import { default as typographycT9iRWTtYPPD9VmzH1edaA5od4wBPzrJU7WPguxASlwMeta } from "/opt/buildhome/repo/pages/typography.vue?macro=true";
import { default as searchVZHeG3_X8t_IjAUUzx95piBYJ_XkAO6zB8qw3F4DYRcMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as seriesI6zBCBp5z4oAq_459JkaDw_LNsgwbiiLVwKKnP58sLiCAMeta } from "/opt/buildhome/repo/pages/mm/series.vue?macro=true";
import { default as newslettervbstlW1aXPob_kb_S6_45vN2SKlT_45S3Ae_D5tttUKfvLsMeta } from "/opt/buildhome/repo/pages/newsletter.vue?macro=true";
import { default as subscriptionoQzVKkE533csOX1M1M_45_PfxAy7jZLRyAgtWsXpsOZPwMeta } from "/opt/buildhome/repo/pages/mm/subscription.vue?macro=true";
import { default as calendarlistfwO_45X44FIWMwk3hN0AKMajMY_451cEPrFubYUt5FXOQcUMeta } from "/opt/buildhome/repo/pages/calendarlist.vue?macro=true";
import { default as calendar7G_ZKYj7p3K4ToVKHWSuO_45xnWt0JLVq1wsfi13UwwSkMeta } from "/opt/buildhome/repo/pages/calendar.vue?macro=true";
import { default as magazineyhX2FNuDDV1VCYbFTG8xpOhzWbwqbdnauDno_45ebqTyEMeta } from "/opt/buildhome/repo/pages/magazine.vue?macro=true";
import { default as themeweek6Nux6N_450mfmnSvafatoriXtOVeoKAcdlQTa5c_7e3BQMeta } from "/opt/buildhome/repo/pages/mm/themeweek.vue?macro=true";
import { default as subjectriKk8brLbIOjA1uGi9lliTNDySN_y4Uo4R6nllAByygMeta } from "/opt/buildhome/repo/pages/mm/subject.vue?macro=true";
import { default as themeWNAA5Wqux_ouGc2oSi0DMwFloJ_455TocsT_45xuIIpEEEwMeta } from "/opt/buildhome/repo/pages/mm/theme.vue?macro=true";
import { default as personlCkc_45Vi8RcD_XBtVRfX6SUxPJkgVQ5_KLGzHUJDhSBYMeta } from "/opt/buildhome/repo/pages/person.vue?macro=true";
import { default as writer8K52r8iOLR6Y92377lIMrt6s_IST0QTiM2vmae303vAMeta } from "/opt/buildhome/repo/pages/writer.vue?macro=true";
import { default as paper1CE84_45BKY7_45GTIyv98Oh3iCnL7p_oz6LGaxLh7EZ6_UMeta } from "/opt/buildhome/repo/pages/mm/paper.vue?macro=true";
import { default as jobs_45JgUSdYDmJFKqL1y3bOMzoUAGdLCXz9WyC6Gn5TAHW4Meta } from "/opt/buildhome/repo/pages/jobs.vue?macro=true";
import { default as jobAdMkMNj5bPyuYyxVuNM7Tv5tRQWu9zRSvNPW0il3w_SFIMeta } from "/opt/buildhome/repo/pages/jobAd.vue?macro=true";
import { default as rss6iXlgMo0Saczc9WqnYCmhcut0UnyZSgTXQvMpOnMYJkMeta } from "/opt/buildhome/repo/pages/rss.vue?macro=true";
import { default as frontpagetOkn7hepk2KgdnXIx_45ZhDG8X9rojZcPYhXw4h_qrsq0Meta } from "/opt/buildhome/repo/pages/mm/frontpage.vue?macro=true";
import { default as newJobsBOYSFupkD6XirlFRsPFyV1HlpHc6Gbc4oI6xW_0jOz8Meta } from "/opt/buildhome/repo/pages/mm/newJobs.vue?macro=true";
import { default as storyInNumbersTeDy8b_45JW8UpxpBGFvqR_lkCdGbCZwetmj7XB6QNgJ8Meta } from "/opt/buildhome/repo/pages/mm/storyInNumbers.vue?macro=true";
import { default as commentswJjs2KKkOF2i25kNgpNX83JVgmTs3fux2vm8FplxhaUMeta } from "/opt/buildhome/repo/pages/mm/comments.vue?macro=true";
import { default as newLeadershipU7_45xIh6sZRRMtxTxQaOZhtmMj0EkFOGP__OYaYauZjIMeta } from "/opt/buildhome/repo/pages/mm/newLeadership.vue?macro=true";
import { default as chiefEditorsBlog7c_45ehFZ4Nh8o_1dkmgAo5a_eLtY1Nr9zVg6ZvpFVzUcMeta } from "/opt/buildhome/repo/pages/mm/chiefEditorsBlog.vue?macro=true";
import { default as techTrendsxMskJyCO8M6O8MBPQ2_gFOPDgT7POuHNWPFRIHj8cesMeta } from "/opt/buildhome/repo/pages/mm/techTrends.vue?macro=true";
import { default as cultureRecommendation50dHOtRuXXYLJ1Ak6hYDepVUlZYHXBPjoBFQNQcGTzYMeta } from "/opt/buildhome/repo/pages/mm/cultureRecommendation.vue?macro=true";
import { default as audioArticlesyIeZT_45RoxVE0m4QtPEkfbuV0SrKsSDKVCwgi_MC0ebUMeta } from "/opt/buildhome/repo/pages/mm/audioArticles.vue?macro=true";
import { default as latestArticles07BA7WfUGtRJk5EoDeLIwZEj_1eY3EnMw1q_wavP128Meta } from "/opt/buildhome/repo/pages/mm/latestArticles.vue?macro=true";
import { default as customerServiceOv57LmMMuyKQK3wLCvhiMm9lXw3N32jkwhheu6WlHNQMeta } from "/opt/buildhome/repo/pages/customerService.vue?macro=true";
import { default as electionCandidateTestResult_zG2M9accn8rGolo_r_WjA_TP7qjdtwudWYM70etgrwMeta } from "/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "article",
    path: "/artikel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "terms",
    path: "/handelsbetingelser",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: "term",
    path: "/handelsbetingelser/:termId",
    component: () => import("/opt/buildhome/repo/pages/term.vue")
  },
  {
    name: "clienttest",
    path: "/ct",
    component: () => import("/opt/buildhome/repo/pages/clienttest.vue")
  },
  {
    name: "prices",
    path: "/Priser",
    component: () => import("/opt/buildhome/repo/pages/prices.vue")
  },
  {
    name: "cookieinfo",
    path: "/cookieinfo",
    component: () => import("/opt/buildhome/repo/pages/cookieinfo.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "ssoLogin",
    path: "/sso-login",
    component: () => import("/opt/buildhome/repo/pages/ssoLogin.vue")
  },
  {
    name: "payment",
    path: "/betal",
    meta: {"url":"/betal.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "afmeld",
    path: "/afmeld",
    meta: {"url":"/afmeld.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "account",
    path: "/konto",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "account_profile",
    path: "/konto/profil",
    component: () => import("/opt/buildhome/repo/pages/account/profile.vue")
  },
  {
    name: "account_loginsecurity",
    path: "/konto/login-sikkerhed",
    component: () => import("/opt/buildhome/repo/pages/account/loginsecurity.vue")
  },
  {
    name: "saved_articles",
    path: "/konto/gemte-artikler",
    component: () => import("/opt/buildhome/repo/pages/account/savedArticles.vue")
  },
  {
    name: "account_decisionchains",
    path: "/konto/beslutningskaeder",
    component: () => import("/opt/buildhome/repo/pages/account/decisionchains.vue")
  },
  {
    name: "account_newsletter",
    path: "/konto/nyhedsbrev",
    component: () => import("/opt/buildhome/repo/pages/account/newsletter.vue")
  },
  {
    name: "account_subscription",
    path: "/konto/abonnement",
    component: () => import("/opt/buildhome/repo/pages/account/subscription.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: {"url":"/admin"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "nejtak",
    path: "/nejtak",
    meta: {"url":"/nejtak.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "usercreatemm",
    path: "/usercreatemm",
    meta: {"url":"/usercreatemm"},
    component: () => import("/opt/buildhome/repo/pages/userCreate.vue")
  },
  {
    name: "userconfirmation",
    path: "/userconfirmation",
    meta: {"url":"/userconfirmation"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "useravatar",
    path: "/useravatar",
    meta: {"url":"/useravatar"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "userforgotpassword",
    path: "/adgangskode",
    component: () => import("/opt/buildhome/repo/pages/user/forgotPassword.vue")
  },
  {
    name: "usersetpassword",
    path: "/usersetpassword",
    component: () => import("/opt/buildhome/repo/pages/userSetPassword.vue")
  },
  {
    name: "copyright",
    path: "/copyright",
    meta: {"url":"/copyright.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "licens",
    path: "/licens",
    meta: {"url":"/licens"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "typography",
    path: "/typography",
    component: () => import("/opt/buildhome/repo/pages/typography.vue")
  },
  {
    name: "search",
    path: "/soeg",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "searchwords",
    path: "/soeg/:words",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "serie",
    path: "/serie/:subject",
    component: () => import("/opt/buildhome/repo/pages/mm/series.vue")
  },
  {
    name: "newsletter",
    path: "/nyhedsbrev",
    component: () => import("/opt/buildhome/repo/pages/newsletter.vue")
  },
  {
    name: "subscription",
    path: "/abonnement",
    component: () => import("/opt/buildhome/repo/pages/mm/subscription.vue")
  },
  {
    name: "subscription_type",
    path: "/abonnement/:typeId",
    component: () => import("/opt/buildhome/repo/pages/mm/subscription.vue")
  },
  {
    name: "cal",
    path: "/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue")
  },
  {
    name: "calendar",
    path: "/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue")
  },
  {
    name: "paper_cal",
    path: "/:paper/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue")
  },
  {
    name: "paper_calendar",
    path: "/:paper/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue")
  },
  {
    name: "magazine",
    path: "/ugebreve",
    component: () => import("/opt/buildhome/repo/pages/magazine.vue")
  },
  {
    name: "themeweek2",
    path: "/ugenstemaer",
    component: () => import("/opt/buildhome/repo/pages/mm/themeweek.vue")
  },
  {
    name: "series",
    path: "/serier",
    component: () => import("/opt/buildhome/repo/pages/mm/themeweek.vue")
  },
  {
    name: "subject",
    path: "/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/mm/subject.vue")
  },
  {
    name: "paper_subject",
    path: "/:paper/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/mm/subject.vue")
  },
  {
    name: "theme",
    path: "/tema/:theme",
    component: () => import("/opt/buildhome/repo/pages/mm/theme.vue")
  },
  {
    name: "person",
    path: "/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue")
  },
  {
    name: "paper_person",
    path: "/:paper/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue")
  },
  {
    name: "writer_new",
    path: "/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue")
  },
  {
    name: "paper_writer",
    path: "/:paper/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue")
  },
  {
    name: "paper",
    path: "/:paper",
    component: () => import("/opt/buildhome/repo/pages/mm/paper.vue")
  },
  {
    name: "paper_article",
    path: "/:paper/artikel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "paper_names",
    path: "/:paper/navnenyt/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "jobs",
    path: "/job",
    component: () => import("/opt/buildhome/repo/pages/jobs.vue")
  },
  {
    name: "job",
    path: "/job/:id",
    component: () => import("/opt/buildhome/repo/pages/jobAd.vue")
  },
  {
    name: "names",
    path: "/navnenyt/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "rss",
    path: "/rss/:id",
    component: () => import("/opt/buildhome/repo/pages/rss.vue")
  },
  {
    name: "frontpage",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/mm/frontpage.vue")
  },
  {
    name: "new-jobs",
    path: "/nyt-job",
    component: () => import("/opt/buildhome/repo/pages/mm/newJobs.vue")
  },
  {
    name: "story-in-numbers",
    path: "/historien-i-tal",
    component: () => import("/opt/buildhome/repo/pages/mm/storyInNumbers.vue")
  },
  {
    name: "comments",
    path: "/kommentarer",
    component: () => import("/opt/buildhome/repo/pages/mm/comments.vue")
  },
  {
    name: "new-leadership",
    path: "/nyt-lederskab",
    component: () => import("/opt/buildhome/repo/pages/mm/newLeadership.vue")
  },
  {
    name: "chief-editors-blog",
    path: "/chefredaktorens-blog",
    component: () => import("/opt/buildhome/repo/pages/mm/chiefEditorsBlog.vue")
  },
  {
    name: "tech-trends",
    path: "/techtendenser",
    component: () => import("/opt/buildhome/repo/pages/mm/techTrends.vue")
  },
  {
    name: "culture-recommendation",
    path: "/kulturanbefalingen",
    component: () => import("/opt/buildhome/repo/pages/mm/cultureRecommendation.vue")
  },
  {
    name: "audio-articles",
    path: "/oplaeste-artikler",
    component: () => import("/opt/buildhome/repo/pages/mm/audioArticles.vue")
  },
  {
    name: "latest-articles",
    path: "/seneste-artikler",
    component: () => import("/opt/buildhome/repo/pages/mm/latestArticles.vue")
  },
  {
    name: "customer-service-index",
    path: "/kundeservice",
    component: () => import("/opt/buildhome/repo/pages/customerService.vue")
  },
  {
    name: "ct-electionCandidateTestResult",
    path: "/ct/electionCandidateTestResult",
    component: () => import("/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue")
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/misc/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/images/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/Scripts/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/scripts/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/css/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/pdffiles/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/arena",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/praktik",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/praktik/",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/glemtadgangskode",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/hotshot",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/hotshot/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/navnenyt.aspx",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/jobannoncer",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/karriere",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/karriere/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/cookie-og-privatlivspolitik",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/handelsbetingelser-for-ugebrevet-mandag-morgen",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/priser-og-betingelser",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/mandag-morgens-redaktion",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/artikel/medarbejdere",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/tjekdet/artikel/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/digital-omstilling/artikel/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/politik/artikel/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/oekonomi/artikel/:pathMatch(.*)",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/oekonomi",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/digital-omstilling",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/politik",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/tjekdet",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/userforgotpassword",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  }
]