export const appHead = {"meta":[{"name":"msapplication-TileImage","content":"/mm_icons/mstile-144x144.png"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"theme-color","content":"#ffffff"},{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/mm_icons/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/mm_icons/apple-touch-icon.png"},{"rel":"apple-touch-icon-precomposed","href":"/mm_icons/apple-touch-icon-precomposed.png"},{"rel":"icon","sizes":"48x48","href":"/mm_icons/favicon-48x48.png"},{"rel":"icon","sizes":"32x32","href":"/mm_icons/favicon-32x32.png"},{"rel":"icon","sizes":"16x16","href":"/mm_icons/favicon-16x16.png"},{"rel":"manifest","href":"/mm_icons/site.webmanifest"},{"rel":"mask-icon","href":"/mm_icons/safari-pinned-tab.svg","color":"#5bbad5"},{"rel":"stylesheet","type":"text/css","href":"https://cloud.typography.com/7227118/6346832/css/fonts.css"}],"style":[],"script":[{"key":"google-tag-manager","innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5H4R9MG');","tagPosition":"head"},{"src":"/advertisement.js","defer":true}],"noscript":[{"innerHTML":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-5H4R9MG\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>"}]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true